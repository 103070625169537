var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (!_vm.isLoading)?_c('div',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('h1',{staticClass:"text-left"},[_vm._v(_vm._s(_vm.configuration.configurationName))])]),_c('v-col',{attrs:{"cols":"12","sm":"auto"}},[_c('v-btn',{staticClass:"align-self-center mt-2 ml-md-4 mb-3 mb-md-0",attrs:{"block":_vm.$vuetify.breakpoint.mobile,"depressed":""},on:{"click":_vm.editConfiguration}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-pencil-outline")]),_vm._v(" Edit ")],1),_c('v-btn',{staticClass:"align-self-center mt-2 ml-md-4 mb-3 mb-md-0",attrs:{"block":_vm.$vuetify.breakpoint.mobile,"color":"error","outlined":"","depressed":""},on:{"click":_vm.deleteConfiguration}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-delete")]),_vm._v(" Delete ")],1)],1)],1),_c('v-simple-table',{staticClass:"my-4",attrs:{"dense":""}},[_c('tbody',[_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Id:")]),_c('td',[_vm._v(_vm._s(_vm.configuration.id))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Name:")]),_c('td',[_vm._v(_vm._s(_vm.configuration.configurationName))])]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Application:")]),_c('td',[_c('router-link',{attrs:{"to":{ name: 'ApplicationDetails', params: { Id: _vm.application.id } }}},[_vm._v(_vm._s(_vm.application.name))])],1)]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Enabled:")]),_c('td',[_c('readonly-checkbox',{attrs:{"value":!_vm.configuration.disabled}})],1)]),_c('tr',[_c('td',{staticClass:"font-weight-bold"},[_vm._v("Last updated:")]),_c('td',[_c('last-updated-field',{attrs:{"item":_vm.configuration}})],1)])])]),_c('div',[_c('div',{staticClass:"mt-8"},[_c('h3',[_vm._v("Licenses using this configuration")]),_c('v-data-table',_vm._b({attrs:{"headers":_vm.licenseHeaders,"items":_vm.configuration.licenses},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'LicenseDetails', params: { Id: item.id } }}},[_vm._v(_vm._s(item.name))])]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('readonly-checkbox',{attrs:{"value":!item.disabled}})]}}],null,true)},'v-data-table',_vm.$util.dataTableAttributes(),false))],1),_c('div',{staticClass:"mt-8"},[_c('h3',[_vm._v("Configuration Files")]),_c('v-data-table',_vm._b({attrs:{"headers":_vm.configurationFileHeaders,"items":_vm.configuration.configurationFiles},scopedSlots:_vm._u([{key:"item.filename",fn:function(ref){
var item = ref.item;
return [_c('router-link',{attrs:{"to":{ name: 'ConfigurationFileDetails', params: { Id: item.id } }}},[_vm._v(_vm._s(item.filename))])]}},{key:"item.disabled",fn:function(ref){
var item = ref.item;
return [_c('readonly-checkbox',{attrs:{"value":!item.disabled}})]}}],null,true)},'v-data-table',_vm.$util.dataTableAttributes(),false))],1)]),_c('create-edit-configuration',{ref:"CreateEditConfigurationRef",on:{"configuration-updated":_vm.bubbleEmit}})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }